import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import AddDiscountCoupon from "../../Components/Admin_components/add_discount_coupons.js";
import Update_gift_card from "../../Components/Admin_components/update_gift_card.js";
import DeleteDiscountCoupon from "../../Components/Admin_components/delete_discount_coupons.js";
import NewsComments from "../../Components/Admin_components/newsComment.js";
import NewsSave from "../../Components/Admin_components/newsSave.js";
import "../../Api/apiUrls";
import { API_URL, getDiscountCoupons, updateDiscountCoupons } from "../../Api/apiUrls";
import Toggle from "react-toggle";

function DiscountCoupons() {
  const [modalAdd_newsIsOpen, setAdd_newsModalIsOpen] = useState("");
  const [modalUpdateDiscountCouponIsOpen, setUpdateDiscountCouponModalIsOpen] = useState(false);
  const [modalDeleteDiscountCouponIsOpen, setDeleteDiscountCouponIsOpen] = useState(false);
  const [modalNewsCommentsIsOpen, setNewsCommentsIsOpen] = useState(false);
  const [modalNewsSaveIsOpen, setNewsSaveIsOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [selectednewsImage, setSelectedNewsImage] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [selectedSoldAt, setSelectedSoldAt] = useState(null);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [toggleStates, setToggleStates] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedNewsKeyword, setSelectedNewsKeyword] = useState(null);
  const [discountCoupon, setDiscountCoupon] = useState([]);
  const [selectedDiscountCouponId, setSelectedDiscountCouponId] = useState(null);
  const [status, setStatus] = useState("All");
  const [id, setId] = useState("");
  const [statusDetails, setStatusDetails] = useState(null);
  const [statusSingle, setStatusSingle] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [newsSelectedAuthor, setNewSelectedAuthor] = useState(null);
  const [pass, setPass] = useState([]);
  const drop = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const toggleSwitch = async (discountCouponId) => {
    try {
      const newToggleState = toggleStates[discountCouponId] === 1 ? 0 : 1;
      const newStatus = newToggleState === 1 ? "Active" : "Inactive";
  
      const response = await fetch(updateDiscountCoupons, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: newStatus,
          discount_coupon_id: discountCouponId,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update toggle state.");
      }
  
      const data = await response.json();
  
      if (data.success) {
        // Update the toggle state
        setToggleStates((prevState) => ({
          ...prevState,
          [discountCouponId]: newToggleState,
        }));
  
        // Update the status in the pass state
        setPass((prevPass) =>
          prevPass.map((item) =>
            item.id === discountCouponId ? { ...item, status: newStatus } : item
          )
        );
  
        console.log("Toggle and status updated successfully:", data);
      } else {
        alert("Failed to update the toggle state. Please try again.");
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
      alert("Failed to update the toggle state. Please try again.");
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlogs = Array.isArray(discountCoupon)
  ? discountCoupon.filter((discountCoupon) => {
      const isStatus =
        !selectedStatus ||
        selectedStatus === "All" ||
        discountCoupon.status === selectedStatus;
      const values = Object.values(discountCoupon).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isStatus && isSearchMatch;
    })
  : [];



  const openAdd_news = () => {
    closeAdd_news();
    setAdd_newsModalIsOpen(true);
  };

  const closeAdd_news = () => {
    setAdd_newsModalIsOpen(false);
  };
  const openUpdateDiscountCoupon = (
    giftCardId,
    name,
    email,
    remark,
    sold_at,
    mobileNumber,
    address
    // status
  ) => {
    setSelectedDiscountCouponId(giftCardId);
    setSelectedName(name);
    setSelectedEmail(email);
    setSelectedRemark(remark);
    setSelectedSoldAt(sold_at);
    setSelectedMobileNumber(mobileNumber);
    setSelectedAddress(address);
    // setSelectedStatus(status);

    closeUpdateDiscountCoupon();
    setUpdateDiscountCouponModalIsOpen(true);
  };

  const closeUpdateDiscountCoupon = () => {
    setUpdateDiscountCouponModalIsOpen(false);
  };
  const openDeleteDiscountCoupon = (giftCardId) => {
    console.log('giftCardId')
    console.log(giftCardId)
    closeDeleteDiscountCoupon();
    setSelectedDiscountCouponId(giftCardId);
    setDeleteDiscountCouponIsOpen(true);
  };

  const closeDeleteDiscountCoupon = () => {
    setDeleteDiscountCouponIsOpen(null);
  };
  const openNewsComments = (id) => {
    closeNewsComments();
    setId(id);
    setNewsCommentsIsOpen(true);
  };

  const closeNewsComments = () => {
    setNewsCommentsIsOpen(null);
  };

  const openNewsSave = (id) => {
    closeNewsSave();
    setId(id);
    setNewsSaveIsOpen(true);
  };

  const closeNewsSave = () => {
    setNewsSaveIsOpen(null);
  };

  useEffect(() => {
    fetch(getDiscountCoupons)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const discountCoupon = data.data.DiscountCoupon;
        setDiscountCoupon(discountCoupon);
        const initialToggleStates = discountCoupon.reduce((acc, item) => {
        acc[item.id] = item.status === "Active" ? 1 : 0;
        return acc;
        }, {});
        setToggleStates(initialToggleStates);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    if (!createdAt) {
      return "--";
    }
  
    const createdAtDate = new Date(createdAt);
  
    // Check if the date is valid
    if (isNaN(createdAtDate)) {
      return "--";
    }
  
    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  
    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return `${datePart} ${timePart}`;
  };
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  
  return (
    <div>
      <Header></Header>

      {modalAdd_newsIsOpen && <AddDiscountCoupon onClose={closeAdd_news} />}
      {/* {modalUpdateDiscountCouponIsOpen && <UpdateDiscountCoupon onClose={closeUpdateDiscountCoupon} />} */}
      {modalNewsCommentsIsOpen && (
        <NewsComments onClose={closeNewsComments} id={id} />
      )}
      {modalNewsSaveIsOpen && <NewsSave onClose={closeNewsSave} id={id} />}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>DiscountCoupons</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={drop}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_news}
                    >
                      <i class="bi bi-person-add"></i> Add Discount Coupon
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : discountCoupon && discountCoupon.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Status</th>
                      <th scope="col">Passes</th>
                      <th scope="col">Coupon Code</th>
                      <th scope="col">Discount Percent (%)</th>
                      <th scope="col">Max Discount Amount</th>
                      <th scope="col">Minimum Order Amount</th>
                      <th scope="col">Usage Limit</th>
                      <th scope="col">Usage Count</th>
                      <th scope="col">Applicable For</th>
                      <th scope="col">Created_On</th>
                      <th scope="col">Expiring_On</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((discountCoupon, index) => (
                      <tr key={discountCoupon.id}>
                        <th scope="row">{index + 1}</th>
                        
                        <td>
                            <Toggle
                                checked={toggleStates[discountCoupon.id] === 1}
                                onChange={() => toggleSwitch(discountCoupon.id)}
                            />
                        </td>
                        <td>
                        {discountCoupon.passes?.length > 0
                            ? discountCoupon.passes.map((pass, index) => (
                                <div key={index}>
                                {pass.title}({pass.duration_type}),
                                <br />
                                </div>
                            ))
                            : "---"}
                        </td>

                        <td>{discountCoupon.code || "---"}</td>
                        <td>
                          {discountCoupon.discount_value || "---"}
                        </td>
                        <td>
                          {discountCoupon.max_discount_amount || "---"}
                        </td>
                        <td>{discountCoupon.min_order_amount || "0"}</td>
                        <td>{discountCoupon.usage_limit || "0"}</td>
                        <td>{discountCoupon.usage_count || "0"}</td>
                        <td>{discountCoupon.applies_to || "0"}</td>
                        <td>{formatCreatedAt(discountCoupon.created_at)}</td>
                        <td>{formatCreatedAt(discountCoupon.expiry_date)}</td>
                        
                        {/* <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdateDiscountCoupon(
                                  discountCoupon.id,
                                  discountCoupon.name,
                                  discountCoupon.email,
                                  discountCoupon.remark,
                                  discountCoupon.sold_at,
                                  discountCoupon.mobileNumber,
                                  discountCoupon.address,
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>  openDeleteDiscountCoupon(discountCoupon.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}

              {modalUpdateDiscountCouponIsOpen && (
                <Update_gift_card
                  giftCardId={selectedDiscountCouponId}
                  name={selectedName}
                  email={selectedEmail}
                  remark={selectedRemark}
                  sold_at={selectedSoldAt}
                  mobileNumber={selectedMobileNumber}
                  address={selectedAddress}
                //   status={selectednewsSummary}
                  onClose={closeUpdateDiscountCoupon}
                />
              )}

              {modalDeleteDiscountCouponIsOpen && (
                <DeleteDiscountCoupon
                  giftCardId={selectedDiscountCouponId}
                  onClose={closeDeleteDiscountCoupon}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length || 0} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default DiscountCoupons;
