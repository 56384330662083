import React, { useState } from "react";
import "../../Pages/Admin/Style.css";
import "./delete_news.css";
import "../../Api/apiUrls";
import { deleteGiftCard } from "../../Api/apiUrls";

function DeleteGiftCard({ giftCardId, onClose }) {
  const [loading, setLoading] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleDelete = () => {
    setLoading(true);
    fetch(deleteGiftCard + giftCardId, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          onClose();
          reloadPage();
          alert("Gift card deleted successfully!");
        } else {
          alert("Failed to delete the gift card: " + data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Error deleting gift card: " + error.message);
      });
  };

  const handleClickOutside = (e) => {
    if (e.target.className === "popup-background") {
      onClose();
      // reloadPage();
    }
  };

  return (
    <div className="popup-background" onClick={handleClickOutside}>
      <div className="popup">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Are you sure?</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                onClose();
                // reloadPage();
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <p>Do you want to delete this gift card? This action can't be undone.</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                onClose();
                // reloadPage();
              }}
            >
              Cancel
            </button>
            <button
              id="btnDelete"
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
              disabled={loading}
            >
              {loading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteGiftCard;
