import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_location.css";
import Select from "react-select";
import { generateGiftCard, getAdminPass } from "../../Api/apiUrls";

function GenerateGiftCard({ onClose }) {
  const [giftCardCount, setGiftCardCount] = useState("");
  const [selectedPass, setSelectedPass] = useState(null);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [passOptions, setPassOptions] = useState([]);
  function reloadPage() {
    window.location.reload();
  }
  // Fetch pass data on component mount
  useEffect(() => {
    const fetchPassData = async () => {
      try {
        const response = await fetch(getAdminPass);
        const data = await response.json();
  
        if (data.success && Array.isArray(data.data.Pass)) {
          // Filter passes that are active
          const activePasses = data.data.Pass.filter((pass) => pass.status === "Active");
  
          // Map the active passes to the desired format
          const options = activePasses.map((pass) => ({
            value: pass.id,
            label: `${pass.title} (Duration: ${pass.duration_type}, Price: ${pass.mrp})`,
          }));
  
          setPassOptions(options);
        } else {
          console.error("Failed to fetch pass data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching pass data:", error);
      }
    };
  
    fetchPassData();
  }, []);
  

  // Handle adding a new location
  const handleAddPass = async () => {
    if (!giftCardCount || !selectedPass) {
      alert("Please fill in all fields");
      return;
    }

    // Ensure giftCardCount is at least 1
    if (giftCardCount < 1) {
      alert("Gift card count must be at least 1");
      return;
    }

    setIsAddingLocation(true);

    try {
      const formData = new FormData();
      formData.append("no_of_cards", giftCardCount);
      formData.append("passes", selectedPass.value);

      const response = await fetch(generateGiftCard, {
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok && responseData.status === 201) {
        alert("Gift Card Generated Successfully");
        setGiftCardCount("");
        setSelectedPass(null);
        onClose();
        reloadPage();
      } else {
        alert("Error generating gift card");
      }
    } catch (error) {
      console.error("Error generating gift card:", error);
      alert("An error occurred");
    } finally {
      setIsAddingLocation(false);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div
          className="modal-content"
          style={{
            width: "100%",
            padding: "2%",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          <div
            className="modal-header"
            style={{
              marginBottom: "3%",
              borderBottom: "solid 1px #7e7a7a8c",
            }}
          >
            <h5 className="modal-title" id="modalAddTitle">
              <b>Generate Gift Card</b>
            </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body" style={{ padding: "2%" }}>
            <form>
              <div className="form-group">
                <label className="small mb-1" htmlFor="giftCardCount">
                  Gift Card Count
                </label>
                <input
                  id="giftCardCount"
                  type="number"
                  className="form-control"
                  value={giftCardCount}
                  onChange={(e) => setGiftCardCount(e.target.value)}
                  min="1" // Ensures no value less than 1 can be entered
                />
              </div>

              <div className="form-group">
                <label className="small mb-1" htmlFor="selectPass">
                  Pass
                </label>
                <Select
                  id="selectPass"
                  options={passOptions}
                  value={selectedPass}
                  onChange={(option) => setSelectedPass(option)}
                />
              </div>
            </form>
          </div>

          <div
            className="modal-footer"
            style={{
              padding: "2%",
              marginTop: "3%",
              borderTop: "solid 1px #7e7a7a8c",
            }}
          >
            <button
              type="button"
              className="btn btn-danger"
              onClick={onClose}
            >
              Close
            </button>

            {isAddingLocation ? (
              <ThreeDots color="#000" height={50} width={50} />
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                style={{ background: "#000", border: "none" }}
                onClick={handleAddPass}
              >
                Generate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateGiftCard;
