import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import "../../Pages/Admin/Style.css";
import "../../Api/apiUrls";
import { UpdateAdminBrand } from "../../Api/apiUrls";

function UpdateBrandRepresentative({
  brandId,
  brandName,
  brandMobile,
  brandEmail,
  br, // Passed as a prop
  em, // Passed as a prop
  brandStatus, // Passed as a prop
  onClose,
}) {
  const [modalUpdateBrandIsOpen, setUpdateBrandModalIsOpen] = useState("");
  const [isAddingnews, setIsAddingnews] = useState(false);
  const [newselectedBrandName, setNewSelectedBrandName] = useState(brandName);
  const [newselectedBrandMobile, setNewSelectedBrandMobile] = useState(brandMobile);
  const [newselectedBrandEmail, setNewSelectedBrandEmail] = useState(brandEmail);
  const [newselectedBrandStatus, setNewSelectedBrandStatus] = useState(null); // Will set this in useEffect
  const [newselectedBrStatus, setNewSelectedBrStatus] = useState(null); // Will set this in useEffect
  const [newselectedEmStatus, setNewSelectedEmStatus] = useState(null); // Will set this in useEffect

  useEffect(() => {
    // Setting the initial dropdown values when the component mounts
    console.log(brandStatus);
    console.log(br);
    console.log(em);
    setNewSelectedBrandStatus({ value: brandStatus, label: brandStatus });
    setNewSelectedBrStatus({ value: br , label: br ? "Yes" : "No" });
    setNewSelectedEmStatus({ value: em, label: em ? "Yes" : "No" });
  }, [brandStatus, br, em]); // Depend on the props to update if they change

  const closeUpdateBrand = () => {
    setUpdateBrandModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleBrChange = (selectedOption) => {
    setNewSelectedBrStatus(selectedOption);
  };

  const handleEmChange = (selectedOption) => {
    setNewSelectedEmStatus(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setNewSelectedBrandStatus(selectedOption);
  };

  const brOp = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const emOp = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleUpdate = () => {
    setIsAddingnews(true);
    const formData = new FormData();
    formData.append("brand_representative_id", brandId);
    formData.append("name", newselectedBrandName);
    formData.append("mobileNumber", newselectedBrandMobile);
    formData.append("email", newselectedBrandEmail);
    formData.append("status", newselectedBrandStatus.label);
    formData.append("brand_representative", newselectedBrStatus.value);
    formData.append("event_manager", newselectedEmStatus.value);


    fetch(UpdateAdminBrand, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsAddingnews(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error updating brand representative:", data.message);
          setIsAddingnews(false);
        }
      })
      .catch((error) => {
        console.error("Error updating brand representative:", error);
        setIsAddingnews(false);
      });
  };

  return (
    <div>
      {modalUpdateBrandIsOpen && <UpdateBrandRepresentative onClose={closeUpdateBrand} />}
      <div className="popup-background">
        <div className="popup">
          <div
            className="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              className="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                className="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Representative</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                //   reloadPage();
                }}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ overflowY: "scroll", padding: "2%" }}>
              <form>
                {/* Name */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Name
                  </label>
                  <input
                    className="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newselectedBrandName}
                    onChange={(e) => setNewSelectedBrandName(e.target.value)}
                  />
                </div>

                {/* Mobile */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Mobile No.
                  </label>
                  <input
                    className="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newselectedBrandMobile}
                    maxLength="10"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) setNewSelectedBrandMobile(value);
                    }}
                  />
                </div>

                {/* Email */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Email
                  </label>
                  <input
                    className="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newselectedBrandEmail}
                    onChange={(e) => setNewSelectedBrandEmail(e.target.value)}
                  />
                  {!newselectedBrandEmail.includes("@") && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                </div>

                {/* Brand Representative */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Brand Representative
                  </label>
                  <div className="child ml-2">
                    <Select
                      value={newselectedBrStatus}
                      onChange={handleBrChange}
                      options={brOp}
                    />
                  </div>
                </div>

                {/* Event Manager */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Event Manager
                  </label>
                  <div className="child ml-2">
                    <Select
                      value={newselectedEmStatus}
                      onChange={handleEmChange}
                      options={emOp}
                    />
                  </div>
                </div>

                {/* Status */}
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Status
                  </label>
                  <div className="child ml-2">
                    <Select
                      value={newselectedBrandStatus}
                      onChange={handleStatusChange}
                      options={status}
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* Modal Footer */}
            <div
              className="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  onClose();
                //   reloadPage();
                }}
              >
                Close
              </button>
              {isAddingnews ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  className="btn btn-primary"
                  style={{ background: "#000", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBrandRepresentative;
