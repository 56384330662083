import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls";
import AddBrandRepresentative from "../../Components/Admin_components/addBrandRepresentative.js";
import DeleteBrandRepresentative from "../../Components/Admin_components/deleteBrandRepresentative.js";
import UpdateBrandRepresentative from "../../Components/Admin_components/updateBrandRepresentative.js";
import { getAdminBrand } from "../../Api/apiUrls";

function BrandRepresentative() {
  const [modalAddBrandIsOpen, setAddBrandModalIsOpen] = useState("");
  const [modalDeleteBrandIsOpen, setDeleteBrandModalIsOpen] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState(null);
  const [selectedBrandMobile, setSelectedBrandMobile] = useState(null);
  const [selectedBrandPassword, setSelectedBrandPassword] = useState(null);
  const [selectedBrandEmail, setSelectedBrandEmail] = useState(null);
  const [selectedBrandImage, setSelectedBrandImage] = useState(null);
  const [selectedBrandStatus, setSelectedBrandStatus] = useState(null);
  const [selectedBrStatus, setSelectedBrStatus] = useState(null);
  const [selectedEmStatus, setSelectedEmStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const openAddBrand = () => {
    closeAddBrand();
    setAddBrandModalIsOpen(true);
  };
  const closeAddBrand = () => {
    setAddBrandModalIsOpen(false);
  };

  const openDeleteBrand = (brandId) => {
    closeDeleteBrand();
    setSelectedBrandId(brandId);
    setDeleteBrandModalIsOpen(true);
  };
  const closeDeleteBrand = () => {
    setDeleteBrandModalIsOpen(false);
  };
  const [modalUpdateBrandIsOpen, setUpdateBrandModalIsOpen] = useState("");
  const openUpdateBrand = (brandId, brandName, brandMobile, brandEmail, brandPassword, brandImage, br, em, brandStatus) => {
    closeUpdateBrand();
    setSelectedBrandId(brandId);
    setSelectedBrandName(brandName);
    setSelectedBrandMobile(brandMobile);
    setSelectedBrandEmail(brandEmail);
    setSelectedBrandPassword(brandPassword);
    setSelectedBrandImage(brandImage);
    setSelectedBrStatus(br);
    setSelectedEmStatus(em);
    setSelectedBrandStatus(brandStatus);
    setUpdateBrandModalIsOpen(true);
  };
  const closeUpdateBrand = () => {
    setUpdateBrandModalIsOpen(false);
  };

  const [brand, setBrand] = useState(false);

  useEffect(() => {
    fetch(getAdminBrand)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const brands = data.data.BrandRepresentative;
        setBrand(brands);

      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEvents = Array.isArray(brand)
    ? brand.filter((brand) => {
      const values = Object.values(brand).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isSearchMatch;
    })
    : [];

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart}`;
  };
  return (
    <div>
      <Header></Header>
      {modalAddBrandIsOpen && (
        <AddBrandRepresentative onClose={closeAddBrand} />
      )}
      {modalDeleteBrandIsOpen && (
        <DeleteBrandRepresentative
          brandId={selectedBrandId}
          onClose={closeDeleteBrand}
        />
      )}
      {modalUpdateBrandIsOpen && (
        <UpdateBrandRepresentative
          brandId={selectedBrandId}
          brandName={selectedBrandName}
          brandMobile={selectedBrandMobile}
          brandEmail={selectedBrandEmail}
          br={selectedBrStatus}
          em={selectedEmStatus}
          brandStatus={selectedBrandStatus}
          onClose={closeUpdateBrand}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Brand Representative</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAddBrand}
                    >
                      <i class="bi bi-person-add"></i> Add Representative
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}>
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : brand.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile No.</th>
                      <th scope="col">Email</th>
                      <th scope="col">Code</th>
                      <th scope="col">Brand Representative</th>
                      <th scope="col">Event Manager</th>
                      <th scope="col">No. of passes sold </th>
                      <th scope="col">Monthly Passes </th>
                      <th scope="col">Quaterly Passes</th>
                      <th scope="col">Half yearly Passes </th>
                      <th scope="col">Annually Passes </th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredEvents.map((brand, index) => (
                      <tr key={brand.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{brand.name || "---"}</td>
                        <td>{brand.mobileNumber || "---"}</td>
                        <td>{brand.email || "---"}</td>
                        <td>
                          {brand.code || "---"}
                        </td>
                        <td>{brand.brand_representative ? 'Yes' : 'No'}</td>
                        <td>{brand.event_manager ? 'Yes' : 'No'}</td>
                        <td>{brand.passes_sell_count || "0"}</td>
                        <td>{brand.monthly_sell_count || "0"}</td>
                        <td>{brand.quterly_sell_count || "0"}</td>
                        <td>{brand.half_yearly_sell_count || "0"}</td>
                        <td>{brand.anually_sell_count || "0"}</td>
                        <td>{brand.status || "---"}</td>
                        <td>{formatCreatedAt(brand.created_at)}</td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdateBrand(
                                  brand.id,
                                  brand.name,
                                  brand.mobileNumber,
                                  brand.email,
                                  brand.password,
                                  brand.profile_image,
                                  brand.brand_representative,
                                  brand.event_manager,
                                  brand.status
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDeleteBrand(brand.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            <br />
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>

                </table>

              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}


            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >{filteredEvents.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>

    </div>
  )
}

export default BrandRepresentative
