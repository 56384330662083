import React, { useState, useEffect, useCallback } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import { getAdminPass, addDiscountCoupon } from "../../Api/apiUrls";
import "../../Pages/Admin/Style.css";
import "./add_discount_coupons.css";

function AddDiscountCoupon({ onClose }) {
  const [code, setCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [maxDiscountAmount, setMaxDiscountAmount] = useState("");
  const [minOrderAmount, setMinOrderAmount] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [appliesTo, setAppliesTo] = useState("passes");
  const [status, setStatus] = useState("Active");
  const [expiryDate, setExpiryDate] = useState("");
  const [selectedPasses, setSelectedPasses] = useState([]);
  const [passOptions, setPassOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPassData = async () => {
      try {
        const response = await fetch(getAdminPass);
        const data = await response.json();
        if (data.success && Array.isArray(data.data.Pass)) {
          const activePasses = data.data.Pass.filter((pass) => pass.status === "Active");
          setPassOptions(
            activePasses.map((pass) => ({
              value: pass.id,
              label: `${pass.title} (Duration: ${pass.duration_type}, Price: ${pass.mrp})`,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching pass data:", error);
      }
    };
    fetchPassData();
  }, []);

  const validateFields = () => {
    if (!code || !discountValue || !maxDiscountAmount || !minOrderAmount || !usageLimit || !expiryDate) {
      alert("Please fill in all fields");
      return false;
    }
    if ((appliesTo === "passes" || appliesTo === "both") && selectedPasses.length === 0) {
      alert("Please select at least one pass");
      return false;
    }
    if ([discountValue, maxDiscountAmount, minOrderAmount, usageLimit].some((val) => Number(val) <= 0)) {
      alert("Please enter valid positive numbers for discount and amounts");
      return false;
    }
    return true;
  };

  const handleSubmit = useCallback(async () => {
    if (!validateFields()) return;

    setIsLoading(true);
    const requestBody = {
      code,
      discount_value: Number(discountValue),
      max_discount_amount: Number(maxDiscountAmount),
      min_order_amount: Number(minOrderAmount),
      usage_limit: Number(usageLimit),
      applies_to: appliesTo,
      expiry_date: expiryDate,
      status,
      passes: selectedPasses.map((pass) => pass.value),
    };

    try {
      const response = await fetch(addDiscountCoupon, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok) {
        alert("Discount Coupon Created Successfully");
        onClose();
      } else {
        alert("Error: " + (responseData.message || "Something went wrong"));
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [code, discountValue, maxDiscountAmount, minOrderAmount, usageLimit, appliesTo, expiryDate, status, selectedPasses, onClose]);

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="modal-content" style={{ padding: "2%", backgroundColor: "#fff", borderRadius: "5px" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="modalAddTitle" style={{ marginBottom: "2%" }}>
              <b>Add Discount Coupon</b>
            </h5>
            <button type="button" className="close" onClick={onClose}>
              &times;
            </button>
          </div>

          <div className="modal-body">
            <form>
              {[
                { label: "Coupon Code", value: code, setter: setCode },
                { label: "Discount Percent", value: discountValue, setter: setDiscountValue, type: "number" },
                { label: "Max Discount Amount", value: maxDiscountAmount, setter: setMaxDiscountAmount, type: "number" },
                { label: "Min Order Amount", value: minOrderAmount, setter: setMinOrderAmount, type: "number" },
                { label: "Usage Limit", value: usageLimit, setter: setUsageLimit, type: "number" },
              ].map(({ label, value, setter, type = "text" }, index) => (
                <div className="form-group" key={index}>
                  <label className="small mb-1">
                    {label} <font color="red">*</font>
                  </label>
                  <input type={type} className="form-control" placeholder={label} value={value} onChange={(e) => setter(e.target.value)} />
                </div>
              ))}

              <div className="form-group">
                <label className="small mb-1">Status:<font color="red">*</font></label>
                <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group">
                <label className="small mb-1">Applies To:<font color="red">*</font></label>
                <select className="form-control" value={appliesTo} onChange={(e) => setAppliesTo(e.target.value)}>
                  <option value="passes">Passes</option>
                  <option value="general">General</option>
                  <option value="both">Both</option>
                </select>
                {(appliesTo === "passes" || appliesTo === "both") && <Select isMulti options={passOptions} value={selectedPasses} onChange={setSelectedPasses} />}
              </div>

              <div className="form-group">
                <label className="small mb-1">Expiry Date:<font color="red">*</font></label>
                <input type="date" className="form-control" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={onClose}>
              Close
            </button>
            {isLoading ? <ThreeDots color="#000" height={50} width={50} /> : <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add</button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDiscountCoupon;
