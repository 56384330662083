import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import "../../Api/apiUrls";
import { addBrand } from "../../Api/apiUrls";

function AddBrandRepresentative({ onClose }) {
    const [modalAddBrandIsOpen, setAddBrandModalIsOpen] = useState("");
    const [isAddingnews, setIsAddingnews] = useState(false);
    const closeAddBrand = () => {
        setAddBrandModalIsOpen(false);
    };
    function reloadPage() {
        window.location.reload();
    }
    const [name, setName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [eventStatus, setEventStatus] = useState("");
    const [brStatus, setBrStatus] = useState("");
    const [emStatus, setEmStatus] = useState("");
    const [file, setIconFile] = useState();
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];
    const br = [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ];
    const em = [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
    ];

    const handleStatusChange = (selectedOption) => {
        setEventStatus(selectedOption.value);
    };
    const handleBrChange = (selectedOption) => {
        setBrStatus(selectedOption.value);
    };
    const handleEmChange = (selectedOption) => {
        setEmStatus(selectedOption.value);
    };

    async function addRepresentative(name, mobileNo, email, eventStatus, brStatus, emStatus) {
        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("mobileNumber", mobileNo);
            formData.append("email", email);
            formData.append("password", "brPass@123");
            formData.append("status", eventStatus);
            formData.append("brand_representative", brStatus);
            formData.append("event_manager", emStatus);

            const response = await fetch(addBrand, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            if (response.ok) {
                if (responseData.status === 201) {
                    return true;
                } else {
                    if (responseData.message){
                        alert(responseData.message);
                    }
                    else{
                        alert("Error adding brand representative. Please try again.");
                    }
                    return false;
                }
            } else {
                throw new Error("Failed to add brand representative");
            }
        } catch (error) {
            console.error("Error adding brand representative:", error);
            return false; // Category addition failed
        }
    }

    const handleAddBrand = async () => {
        if (name && mobileNo && email && eventStatus && String(brStatus) && String(emStatus)) {
            try {
                setIsAddingnews(true);  // Start loading indicator or disable button
                
                const result = await addRepresentative(name, mobileNo, email, eventStatus, brStatus, emStatus);
                
                if (result) {
                    alert("Brand representative added successfully.");
                    onClose(); // Close the modal
                    window.location.reload(); // Refresh the page
                } 
                // else {
                //     alert("Error adding brand representative. Please try again.");
                // }
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                alert("An unexpected error occurred. Please try again later.");
            } finally {
                setIsAddingnews(false);  // Stop loading indicator
            }
        } else {
            alert("Please fill all fields!");
        }
    };
    
    return (
        <div>
            {modalAddBrandIsOpen && (
                <AddBrandRepresentative onClose={closeAddBrand} />
            )}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Representative</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    // reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Name<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Mobile No.<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={mobileNo}
                                        maxLength="10"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) setMobileNo(value); // Ensures only digits
                                        }}
                                    />

                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Email<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}

                                    />
                                    {!email.includes("@") && (
                                        <p style={{ color: "red", fontSize: "12px" }}>
                                            Please enter a valid email address.
                                        </p>
                                    )}
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Brand Representative<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleBrChange}
                                            options={br}
                                        ></Select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Event Manager<font color="red" style={{ fontSize: "10px" }}>
                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleEmChange}
                                            options={em}
                                        ></Select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    // reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleAddBrand}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddBrandRepresentative
