import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import GenerateGiftCard from "../../Components/Admin_components/generate_giftcard.js";
import Update_gift_card from "../../Components/Admin_components/update_gift_card.js";
import DeleteGiftCard from "../../Components/Admin_components/delete_gift_card.js";
import NewsComments from "../../Components/Admin_components/newsComment.js";
import NewsSave from "../../Components/Admin_components/newsSave.js";
import "../../Api/apiUrls";
import { API_URL, getGiftCards } from "../../Api/apiUrls";

function GiftCard() {
  const [modalAdd_newsIsOpen, setAdd_newsModalIsOpen] = useState("");
  const [modalUpdateGiftCardIsOpen, setUpdateGiftCardModalIsOpen] = useState(false);
  const [modalDeleteGiftCardIsOpen, setDeleteGiftCardIsOpen] = useState(false);
  const [modalNewsCommentsIsOpen, setNewsCommentsIsOpen] = useState(false);
  const [modalNewsSaveIsOpen, setNewsSaveIsOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [selectednewsImage, setSelectedNewsImage] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [selectedSoldAt, setSelectedSoldAt] = useState(null);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedNewsKeyword, setSelectedNewsKeyword] = useState(null);
  const [giftCard, setGiftCard] = useState([]);
  const [selectedGiftCardId, setSelectedGiftCardId] = useState(null);
  const [status, setStatus] = useState("All");
  const [id, setId] = useState("");
  const [statusDetails, setStatusDetails] = useState(null);
  const [statusSingle, setStatusSingle] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [newsSelectedAuthor, setNewSelectedAuthor] = useState(null);
  const drop = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlogs = Array.isArray(giftCard)
  ? giftCard.filter((giftCard) => {
      const isStatus =
        !selectedStatus ||
        selectedStatus === "All" ||
        giftCard.status === selectedStatus;
      const values = Object.values(giftCard).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isStatus && isSearchMatch;
    })
  : [];



  const openAdd_news = () => {
    closeAdd_news();
    setAdd_newsModalIsOpen(true);
  };

  const closeAdd_news = () => {
    setAdd_newsModalIsOpen(false);
  };
  const openUpdateGiftCard = (
    giftCardId,
    name,
    email,
    remark,
    sold_at,
    mobileNumber,
    address
    // status
  ) => {
    setSelectedGiftCardId(giftCardId);
    setSelectedName(name);
    setSelectedEmail(email);
    setSelectedRemark(remark);
    setSelectedSoldAt(sold_at);
    setSelectedMobileNumber(mobileNumber);
    setSelectedAddress(address);
    // setSelectedStatus(status);

    closeUpdateGiftCard();
    setUpdateGiftCardModalIsOpen(true);
  };

  const closeUpdateGiftCard = () => {
    setUpdateGiftCardModalIsOpen(false);
  };
  const openDeleteGiftCard = (giftCardId) => {
    console.log('giftCardId')
    console.log(giftCardId)
    closeDeleteGiftCard();
    setSelectedGiftCardId(giftCardId);
    setDeleteGiftCardIsOpen(true);
  };

  const closeDeleteGiftCard = () => {
    setDeleteGiftCardIsOpen(null);
  };
  const openNewsComments = (id) => {
    closeNewsComments();
    setId(id);
    setNewsCommentsIsOpen(true);
  };

  const closeNewsComments = () => {
    setNewsCommentsIsOpen(null);
  };

  const openNewsSave = (id) => {
    closeNewsSave();
    setId(id);
    setNewsSaveIsOpen(true);
  };

  const closeNewsSave = () => {
    setNewsSaveIsOpen(null);
  };

  useEffect(() => {
    fetch(getGiftCards)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const giftCard = data.data.GiftCard;
        setGiftCard(giftCard);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    if (!createdAt) {
      return "--";
    }
  
    const createdAtDate = new Date(createdAt);
  
    // Check if the date is valid
    if (isNaN(createdAtDate)) {
      return "--";
    }
  
    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  
    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return `${datePart} ${timePart}`;
  };
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  
  return (
    <div>
      <Header></Header>

      {modalAdd_newsIsOpen && <GenerateGiftCard onClose={closeAdd_news} />}
      {/* {modalUpdateGiftCardIsOpen && <UpdateGiftCard onClose={closeUpdateGiftCard} />} */}
      {modalNewsCommentsIsOpen && (
        <NewsComments onClose={closeNewsComments} id={id} />
      )}
      {modalNewsSaveIsOpen && <NewsSave onClose={closeNewsSave} id={id} />}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>GiftCard</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={drop}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_news}
                    >
                      <i class="bi bi-person-add"></i> Generate Gift Card
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : giftCard && giftCard.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Pass</th>
                      <th scope="col">Card Number</th>
                      <th scope="col">Pass Code</th>
                      <th scope="col">Alloted to</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Remaining Balance</th>
                      <th scope="col">Credits</th>
                      <th scope="col">Balance Credits</th>
                      <th scope="col">Sold At (Without GST)</th>
                      <th scope="col">Created_On</th>
                      <th scope="col">Expiring_On</th>
                      <th scope="col">Used By</th>
                      <th scope="col">Redeemed_On</th>
                      <th scope="col">Status</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((giftCard, index) => (
                      <tr key={giftCard.id}>
                        <th scope="row">{index + 1}</th>
                        
                        <td>{giftCard.passes?.title || "---"}
                            <br />
                            {giftCard.passes?.duration_type || "---"}
                        <br />
                        </td>
                        <td>{giftCard.card_code || "---"}</td>
                        <td>
                          {giftCard.passcode || "---"}
                        </td>
                        <td>
                          {giftCard.name || "---"}
                          <br />
                          {giftCard.email || "---"}
                          <br />
                          {giftCard.mobileNumber || "---"}
                          <br />
                          {giftCard.address || "---"}
                        </td>
                        <td>{giftCard.amount || "0"}</td>
                        <td>{giftCard.remaining_balance || "0"}</td>
                        <td>{giftCard.credits || "0"}</td>
                        <td>{giftCard.balance_credits || "0"}</td>
                        <td>{giftCard.sold_at || "0"}</td>
                        <td>{formatCreatedAt(giftCard.created_at)}</td>
                        <td>{formatCreatedAt(giftCard.expiry_date)}</td>
                        <td>
                          {giftCard.used_by?.name || "-"}
                          <br />
                          {giftCard.used_by?.email || "-"}
                        </td>
                        <td>{formatCreatedAt(giftCard.redeemed_at) || "-"}</td>
                        <td>{giftCard.status || "-"}</td>
                        <td>{giftCard.remark || "-"}</td>
                        
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdateGiftCard(
                                  giftCard.id,
                                  giftCard.name,
                                  giftCard.email,
                                  giftCard.remark,
                                  giftCard.sold_at,
                                  giftCard.mobileNumber,
                                  giftCard.address,
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>  openDeleteGiftCard(giftCard.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}

              {modalUpdateGiftCardIsOpen && (
                <Update_gift_card
                  giftCardId={selectedGiftCardId}
                  name={selectedName}
                  email={selectedEmail}
                  remark={selectedRemark}
                  sold_at={selectedSoldAt}
                  mobileNumber={selectedMobileNumber}
                  address={selectedAddress}
                //   status={selectednewsSummary}
                  onClose={closeUpdateGiftCard}
                />
              )}

              {modalDeleteGiftCardIsOpen && (
                <DeleteGiftCard
                  giftCardId={selectedGiftCardId}
                  onClose={closeDeleteGiftCard}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length || 0} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default GiftCard;
