import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_categories.css";
import { UpdateGiftCardApi } from "../../Api/apiUrls";

function Update_gift_card({ giftCardId, name, email, remark, sold_at, mobileNumber, address, onClose }) {
    const [newName, setNewName] = useState(name || "");
    const [newEmail, setNewEmail] = useState(email || "");
    const [newRemark, setNewRemark] = useState(remark || "");
    const [newSoldAt, setNewSoldAt] = useState(sold_at >= 0 ? sold_at : "");
    const [newMobileNumber, setNewMobileNumber] = useState(mobileNumber || "");
    const [newAddress, setNewAddress] = useState(address || "");
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
    
        // Allow null or empty values, but if a value exists, it must be valid
        tempErrors.name = newName === "" ? "" : newName ? "" : "Name is required";
        tempErrors.email = newEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail) ? "" : "Valid email is required";
        tempErrors.mobileNumber = newMobileNumber === "" || /^[0-9]{10}$/.test(newMobileNumber) ? "" : "Valid 10-digit mobile number is required";
        tempErrors.address = newAddress === "" ? "" : newAddress ? "" : "Address is required";
        tempErrors.soldAt = newSoldAt !== "" && newSoldAt >= 0 ? "" : "Sold At must be 0 or greater";
        tempErrors.remark = newRemark === "" ? "" : newRemark ? "" : "Remark is required";
    
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };
    

    const handleUpdate = () => {
        if (!validate()) return;
        setIsUpdating(true);
        const formData = new FormData();
        formData.append("gift_card_id", giftCardId);
        formData.append("name", newName);
        formData.append("email", newEmail);
        formData.append("mobileNumber", newMobileNumber);
        formData.append("address", newAddress);
        formData.append("remark", newRemark);
        formData.append("sold_at", newSoldAt);

        fetch(UpdateGiftCardApi, {
            method: "PUT",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Gift Card updated successfully!");
                    onClose();
                    window.location.reload();
                } else {
                    alert("Error updating gift card");
                }
                setIsUpdating(false);
            })
            .catch((error) => {
                console.error("Error updating gift card:", error);
                setIsUpdating(false);
            });
    };

    return (
        <div className="popup-background">
            <div className="popup">
                <div className="modal-content" style={{ width: "100%", padding: "2%", backgroundColor: "#fff", borderRadius: "5px" }}>
                    <div className="modal-header" style={{ width: "100%", marginBottom: "3%", borderBottom: "solid 1px #7e7a7a8c" }}>
                        <h5 className="modal-title" id="modalAddTitle" style={{ marginBottom: "2%" }}>
                            <b>Update Gift Card</b>
                        </h5>
                        <button type="button" onClick={onClose} className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body" style={{ maxHeight: "400px", overflowY: "auto", padding: "2%" }}>
                        <form>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="name">Name</label>
                                <input className="form-control" id="name" type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                                <small className="text-danger">{errors.name}</small>
                            </div>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="email">Email</label>
                                <input className="form-control" id="email" type="text" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                                <small className="text-danger">{errors.email}</small>
                            </div>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="mobileNumber">Mobile Number</label>
                                <input className="form-control" id="mobileNumber" type="number" value={newMobileNumber} onChange={(e) => setNewMobileNumber(e.target.value)} />
                                <small className="text-danger">{errors.mobileNumber}</small>
                            </div>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="address">Address</label>
                                <input className="form-control" id="address" type="text" value={newAddress} onChange={(e) => setNewAddress(e.target.value)} />
                                <small className="text-danger">{errors.address}</small>
                            </div>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="soldAt">Sold At</label>
                                <input className="form-control" id="soldAt" type="number" value={newSoldAt} onChange={(e) => setNewSoldAt(e.target.value)} />
                                <small className="text-danger">{errors.soldAt}</small>
                            </div>
                            <div className="form-group">
                                <label className="small mb-1" htmlFor="remark">Remark<font color="red">*</font></label>
                                <textarea style={{ height: "100px" }} className="form-control" id="remark" value={newRemark} onChange={(e) => setNewRemark(e.target.value)} />
                                <small className="text-danger">{errors.remark}</small>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer" style={{ padding: "2%", marginTop: "3%", borderTop: "solid 1px #7e7a7a8c" }}>
                        <button type="button" className="btn btn-danger" onClick={onClose}>Close</button>
                        {isUpdating ? (
                            <ThreeDots color="#000" height={50} width={50} />
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleUpdate} style={{ background: "#000", border: "none" }}>
                                Update
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Update_gift_card;
